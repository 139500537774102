import types from "./report.types";
import { apiAction } from "../api/actions";
import settings from "../api/settings";

export const getOwnMunicipalityReportSuccess = (data) => {
  return {
    type: types.FETCH_OWN_MUNICIPALITY_REPORT_SUCCESS,
    payload: data,
  };
};

export const getMunicipalityReportSuccess = (data) => {
  return {
    type: types.FETCH_MUNICIPALITY_REPORT_SUCCESS,
    payload: data,
  };
};

export const getProvinceReportSuccess = (data) => {
  return {
    type: types.FETCH_PROVINCE_REPORT_SUCCESS,
    payload: data,
  };
};

export const getCountryReportSuccess = (data) => {
  return {
    type: types.FETCH_COUNTRY_REPORT_SUCCESS,
    payload: data,
  };
};

export const getCSVReportSuccess = (data) => {
  return {
    type: types.FETCH_CSV_REPORT_SUCCESS,
    payload: data,
  };
};
export const getExcelReportSuccess = (data) => {
  return {
    type: types.FETCH_EXCEL_REPORT_SUCCESS,
    payload: data,
  };
};

const reportAPICallError = (error) => {
  let data = "Connection error";
  if (error.response !== undefined) data = error.response.data;
  return {
    type: types.REPORT_API_ERROR,
    payload: data,
  };
};

export const getOwnMunicipalityReport = (municipalityId, startDate, endDate, functionName, locationArray, organizationArray, serviceArray, onSuccess) => {
  var data = {
        startDate: startDate,
        endDate: endDate,
        functionName: functionName,
        locationArray: locationArray,
        organizationArray: organizationArray,
        serviceArray: serviceArray
  }
  if (municipalityId) {
    data.municipalityId = municipalityId;
  }

  return apiAction({
    url: `${settings.baseURL}/reports/municipality`,
    method: "POST",
    data: data,
    onSuccess: onSuccess,
    onFailure: reportAPICallError,
    label: types.FETCH_OWN_MUNICIPALITY_REPORT,
  });
};

export const getMunicipalityReport = (municipalityId, startDate, endDate, onSuccess) => {
  return apiAction({
    url: `${settings.baseURL}/reports/municipality`,
    method: "POST",
    data: {
      startDate: startDate,
      endDate: endDate,
      municipalityId: municipalityId
    },
    onSuccess: onSuccess,
    onFailure: reportAPICallError,
    label: types.FETCH_MUNICIPALITY_REPORT,
  });
};

export const getProvinceReport = (
  provinceId,
  startDate,
  endDate,
  onSuccess
) => {
  return apiAction({
    url: `${settings.baseURL}/reports/area?provinceId=${provinceId}&startDate=${startDate}&endDate=${endDate}`,
    onSuccess: onSuccess,
    onFailure: reportAPICallError,
    label: types.FETCH_PROVINCE_REPORT,
  });
};

export const getCountryReport = (startDate, endDate, onSuccess) => {
  return apiAction({
    url: `${settings.baseURL}/reports/area?startDate=${startDate}&endDate=${endDate}`,
    onSuccess: onSuccess,
    onFailure: reportAPICallError,
    label: types.FETCH_COUNTRY_REPORT,
  });
};

export const getCSVReport = (data, onSuccess) => {
  return apiAction({
    url: `${settings.baseURL}/reports/csv`,
    method: "POST",
    data: data,
    onSuccess: onSuccess,
    onFailure: reportAPICallError,
    label: types.FETCH_CSV_REPORT,
    responseType: "blob",
  });
};

export const getExcelReport = (data, onSuccess) => {
  return apiAction({
    url: `${settings.baseURL}/reports/excel`,
    method: "POST",
    data: data,
    onSuccess: onSuccess,
    onFailure: reportAPICallError,
    label: types.FETCH_CSV_REPORT,
    responseType: "blob",
  });
};
export const getMunicipalityCSVReport = (data, onSuccess) => {
  return apiAction({
    url: `${settings.baseURL}/reports/csv/municipality`,
    method: "POST",
    data: data,
    onSuccess: onSuccess,
    onFailure: reportAPICallError,
    label: types.FETCH_CSV_REPORT,
    responseType: "blob",
  });
};

export const getMunicipalityGrid = (onSuccess, onFailure) => {
  return apiAction({
    url: `${settings.baseURL}/reports/municipality/grid`,
    onSuccess: onSuccess,
    onFailure: onFailure,
    label: types.FETCH_MUNICIPALITY_GRID
  })
}
