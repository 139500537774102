import types from "./function.types";
import {apiAction} from "../api/actions";
import settings from "../api/settings";
import {getComparator} from "../../utils";

export const setEmptyAction = data => {
    return {
        type: types.SET_EMPTY_ACTION,
        payload: data
    }
}

const setNewLink = data => {
    return {
        type: types.UPDATE_FUNCTION_LINKS,
        payload: data
    }
}

const setFeedbackReport = data => {
    return {
        type: types.FEEDBACK_REPORT,
        payload: data
    }
}

const setFeedbackList = data => {
    return {
        type: types.LIST_FEEDBACK,
        payload: data
    }
}

const setSharedMunicipalitiesList = data => {
    return {
        type: types.LIST_SHARED_MUNICIPALITIES,
        payload: data
    }
}

export const setFunctions = data => {
    return {
        type: types.SET_FUNCTIONS_LIST,
        payload: data
    };
}
const setFunctionTemplates = data => {
    return {
        type: types.SET_FUNCTION_TEMPLATES_LIST,
        payload: data
    }
}

export const setYouthData = data => {
    return {
        type: types.SET_YOUTHINFO,
        payload: data
    }
}

const functionAPICallError = error => {
    let data = "Connection error";
    console.log(error);
    if (error.response !== undefined) data = error.response.data;
    return {
        type: types.FUNCTION_API_ERROR,
        payload: data
    }
}

export const createFunction = (data, createTemplate, onSuccess, onlyTemplate = false) => {
    if (data.locationId === -1) {
        data.locationId = "";
    }
    let url = `${settings.baseURL}/functions`;
    if (createTemplate) {
        url = `${settings.baseURL}/functions/template`;
        if (onlyTemplate) {
            url = `${settings.baseURL}/functions/template/new`;
        }
    }
    return apiAction({
        url: url,
        method: 'POST',
        data: data,
        onSuccess: onSuccess,
        onFailure: functionAPICallError,
        label: types.CREATE_FUNCTION
    });
};


export const createSharedFunction = (data, onSuccess) => {
    let url = `${settings.baseURL}/functions/shared`;
    return apiAction({
        url: url,
        method: 'POST',
        data: data,
        onSuccess: onSuccess,
        onFailure: functionAPICallError,
        label: types.CREATE_FUNCTION
    });
};

export const updateSharedFunction = (data, onSuccess) => {
    let url = `${settings.baseURL}/functions/shared/update`;
    return apiAction({
        url: url,
        method: 'POST',
        data: data,
        onSuccess: onSuccess,
        onFailure: functionAPICallError,
        label: types.UPDATE_FUNCTION
    });
};

export const listFunctions = (filters, onSuccess) => {
    let queryStr = "";
    if (filters != null && filters.municipalityId != null) {
        queryStr = `?municipalityId=${filters.municipalityId}&functionType=${filters.functionType}` 
        + (filters.startDate != null ? `&startDate=${filters.startDate}` : "") 
        + (filters.endDate != null ? `&endDate=${filters.endDate}` : "")
        + (filters.filter != null ?  `&filter=${filters.filter}` : "")
        + (filters.locationId != null && filters.locationId !== -1 ?  `&locationId=${filters.locationId}` : "")
        + (filters.offset != null ? `&offset=${filters.offset}` : "")
        + (filters.limit != null ? `&limit=${filters.limit}` : "")
        + (filters.own === true ? `&own=true` : "")
    }
    if(onSuccess === undefined) {
        onSuccess = setFunctions;
    }
    return apiAction({
        url: `${settings.baseURL}/functions${queryStr}`,
        onSuccess: (data) => {
            if (filters.offset != null && filters.offset > 0 && data.length === 0) {
                return {
                    type: types.SET_EMPTY_ACTION
                }
            }
            return onSuccess(data);
        },
        onFailure: functionAPICallError,
        label: types.FETCH_FUNCTIONS_LIST
    });
}

export const getSortedLocations = (locations, organizations, authenticatedUser, randomLocation) => {
    const response = [];

    for (const location of locations) {
        const org = location.organizationId ? organizations.find(obj => obj.id === location.organizationId) : null;
        const row = {
            id: location.id,
            shortName: location.name,
            orgName: org ? org.name.trim() : null,
            priority: org && authenticatedUser.organizations && authenticatedUser.organizations.find(obj => obj.id === org.id)
        }
        row.name = row.shortName + (row.orgName ? " - "+row.orgName : "")
        response.push(row);
    }
    const comparator = getComparator();
    response.sort((a, b) => {
        if (a.orgName == null && b.orgName != null) {
            return 1;
        }
        if (a.orgName != null && b.orgName == null) {
            return -1;
        }
        if (a.priority && !b.priority) {
            return -1;
        }
        if (!a.priority && b.priority) {
            return 1;
        }

        const v1 = comparator.compare(a.orgName,b.orgName);

        if (v1 === 0) { //a.orgName == null && b.orgName == null) ||
            return comparator.compare(a.shortName,b.shortName)
        }
        return v1;
    } );
    response.unshift({
        id: -1,
        name: randomLocation
    });

    return response;
}


export const getFeedbackReport = (eventId) => {
    return apiAction({
        url: `${settings.baseURL}/functions/feedback/report/${eventId}`,
        onSuccess: setFeedbackReport,
        onFailure: functionAPICallError,
        label: types.FEEDBACK_REPORT

    })
}

export const getYouthCardInfo = (eventId) => {
    return apiAction({
        url: `${settings.baseURL}/function-api/get/${eventId}`,
        onSuccess: setYouthData,
        onFailure: functionAPICallError,
        label: types.FETCH_YOUTHINFO
    }
    )
}

export const recreateLinksForFunction = (functionId) => {
    return apiAction({
        url: `${settings.baseURL}/functions/updatecode/${functionId}`,
        onSuccess: setNewLink,
        onFailure: functionAPICallError,
        label: types.UPDATE_FUNCTION_LINKS
    })
}

export const deleteFeedback = (feedbackItemId) => {
    return apiAction({
        url: `${settings.baseURL}/functions/feedback/delete/${feedbackItemId}`,
        onSuccess: setFeedbackList,
        onFailure: functionAPICallError,
        label: types.LIST_FEEDBACK
    });
}

export const listFeedback = (eventId) => {
    return apiAction({
        url: `${settings.baseURL}/functions/feedback/list/${eventId}`,
        onSuccess: setFeedbackList,
        onFailure: functionAPICallError,
        label: types.LIST_FEEDBACK
    });
}

export const listFunctionTemplates = (municipalityId) => {
    return apiAction({
        url: `${settings.baseURL}/functions/template/${municipalityId}`,
        onSuccess: setFunctionTemplates,
        onFailure: functionAPICallError,
        label: types.FETCH_FUNCTIONS_LIST
    });
}

export const getFunction = (id, isShared = false) => {
    return apiAction({
        url: isShared ? `${settings.baseURL}/functions/shared/${id}` : `${settings.baseURL}/functions/${id}`,
        onSuccess: setFunction,
        onFailure: functionAPICallError,
        label: types.FETCH_FUNCTION
    });
}

export const getSharedMunicipalities = (id) => {
    return apiAction({
        url: `${settings.baseURL}/functions/shared/municipalities/${id}`,
        onSuccess: setSharedMunicipalitiesList,
        onFailure: functionAPICallError,
        label: types.LIST_SHARED_MUNICIPALITIES
    });
}

export const setFunction = data => {
    return {
        type: types.SET_FUNCTION,
        payload: data
    };
}

export const unsetFunction = () => {
    return {
        type: types.UNSET_FUNCTION,
        payload: null
    };
}

export const saveFunction = (id, data, onSuccess) => {
    let url = `${settings.baseURL}/functions/${id}`;
    if (data.locationId === -1) {
        data.locationId = "";
    }
    return apiAction({
        url: url,
        method: 'PUT',
        data: data,
        onSuccess: onSuccess,
        onFailure: functionAPICallError,
        label: types.UPDATE_FUNCTION
    });
}

export const archiveFunction = (id, shareEventId, onSuccess) => {
    return apiAction({
        url: shareEventId !== null ? `${settings.baseURL}/functions/shared/delete/${shareEventId}` : `${settings.baseURL}/functions/${id}/archive`,
        method: 'POST',
        onSuccess: onSuccess,
        onFailure: functionAPICallError,
        label: types.ARCHIVE_FUNCTION
    });
};

export const updateFunctionTemplate = (id, data, onSuccess) => {
    if (data.locationId === -1) {
        data.locationId = "";
    }
    let url = `${settings.baseURL}/functions/template/${id}`;
    return apiAction({
        url: url,
        method: 'PUT',
        data: data,
        onSuccess: onSuccess,
        onFailure: functionAPICallError,
        label: types.UPDATE_FUNCTION_TEMPLATE
    });
}

export const deleteFunctionTemplate = (id, onSuccess) => {
    return apiAction({
        url: `${settings.baseURL}/functions/template/${id}/delete`,
        method: 'POST',
        onSuccess: onSuccess,
        onFailure: functionAPICallError,
        label: types.DELETE_FUNCTION_TEMPLATE
    });
};

export const removemeFunctionTemplate = (id, onSuccess) => {
    return apiAction({
        url: `${settings.baseURL}/functions/template/${id}/removeme`,
        method: 'POST',
        onSuccess: onSuccess,
        onFailure: functionAPICallError,
        label: types.REMOVEME_FUNCTION_TEMPLATE
    });
}

export const getLocationTypeOptions = (t, locationTypes) => {
    return sortAndCreate(t, locationTypes);
}

export const getServiceOptions = (t, services) => {
    return sortAndCreate(t, services);
}

const sortAndCreate = (t, list) => {
    const response = [];
    for (const i in list) {
        const l = list[i];
        if (l.displayOrder === -1) {
            continue;
        }

        var text = t('report.current_language') === "FINNISH" ? l.nameFi : l.nameSv;

        if (l.parentId == null) {
            text = "-- "+text+" --";
        }

        response.push({value: l.id, label: text, descriptionFi: l.descriptionFi, descriptionSv: l.descriptionSv, displayOrder: l.displayOrder, disabled: l.parentId == null});
    }
    response.sort((a, b) => a.displayOrder - b.displayOrder);

    return response;
}
