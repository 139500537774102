import {valueFoundInArray} from "./utils";

export const ROLE_ADMIN_SYSTEM = "ROLE_ADMIN_SYSTEM";
export const ROLE_ADMIN_MUNICIPALITY = "ROLE_ADMIN_MUNICIPALITY";
export const ROLE_LEADER_MUNICIPALITY = "ROLE_LEADER_MUNICIPALITY";
export const ROLE_ADMIN_ORGANIZATION = "ROLE_ADMIN_ORGANIZATION";
export const ROLE_EMPLOYEE_MUNICIPALITY = "ROLE_EMPLOYEE_MUNICIPALITY";
export const ROLE_EMPLOYEE_ORGANIZATION = "ROLE_EMPLOYEE_ORGANIZATION";
export const ROLE_GUEST = "ROLE_GUEST";

export const PERMISSION_AREAS = 'PERMISSION_AREAS';
export const PERMISSION_CREATE_MUNICIPALITY = 'PERMISSION_CREATE_MUNICIPALITY';
export const PERMISSION_CREATE_OWN_MUNICIPALITY = 'PERMISSION_CREATE_OWN_MUNICIPALITY';
export const PERMISSION_CREATE_ORGANIZATION = 'PERMISSION_CREATE_ORGANIZATION';
export const PERMISSION_EDIT_MUNICIPALITY = 'PERMISSION_EDIT_MUNICIPALITY';
export const PERMISSION_EDIT_ORGANIZATION = 'PERMISSION_EDIT_ORGANIZATION';
export const PERMISSION_VIEW_MUNICIPALITIES = 'PERMISSION_VIEW_MUNICIPALITIES';
export const PERMISSION_VIEW_ORGANIZATIONS = 'PERMISSION_VIEW_ORGANIZATIONS';
export const PERMISSION_LOCATIONS = 'PERMISSION_LOCATIONS';
export const PERMISSION_CREATE_LOCATION_TO_ANY_MUNICIPALITY = 'PERMISSION_CREATE_LOCATION_TO_ANY_MUNICIPALITY';
export const PERMISSION_CREATE_LOCATION_TO_OWN_MUNICIPALITY = 'PERMISSION_CREATE_LOCATION_TO_OWN_MUNICIPALITY';
export const PERMISSION_CREATE_LOCATION_TO_OWN_ORGANIZATION = 'PERMISSION_CREATE_LOCATION_TO_OWN_ORGANIZATION';
export const PERMISSION_EDIT_LOCATION = 'PERMISSION_EDIT_LOCATION';
export const PERMISSION_ARCHIVE_LOCATION = 'PERMISSION_ARCHIVE_LOCATION';
export const PERMISSION_ARCHIVE_OWN_ORGANIZATIONS_LOCATION = 'PERMISSION_ARCHIVE_OWN_ORGANIZATIONS_LOCATION';
export const PERMISSION_RESTORE_LOCATION = 'PERMISSION_RESTORE_LOCATION';
export const PERMISSION_VIEW_LOCATIONS = 'PERMISSION_VIEW_LOCATIONS';

export const PERMISSION_FUNCTIONS = 'PERMISSION_FUNCTIONS';
export const PERMISSION_CREATE_FUNCTION_ANY_MUNICIPALITY = 'PERMISSION_CREATE_FUNCTION_ANY_MUNICIPALITY';
export const PERMISSION_CREATE_FUNCTION_BEHALF_OF_ANOTHER = 'PERMISSION_CREATE_FUNCTION_BEHALF_OF_ANOTHER';
export const PERMISSION_CREATE_OWN_FUNCTION = 'PERMISSION_CREATE_OWN_FUNCTION';
export const PERMISSION_EDIT_ANY_MUNICIPALITY_FUNCTION_TEMPLATE = 'PERMISSION_EDIT_ANY_MUNICIPALITY_FUNCTION_TEMPLATE';
export const PERMISSION_EDIT_ANY_FUNCTION_TEMPLATE = 'PERMISSION_EDIT_ANY_FUNCTION_TEMPLATE';
export const PERMISSION_EDIT_OWN_FUNCTION_TEMPLATE = 'PERMISSION_EDIT_OWN_FUNCTION_TEMPLATE';
export const PERMISSION_CREATE_FUNCTION_TEMPLATE = 'PERMISSION_CREATE_FUNCTION_TEMPLATE';
export const PERMISSION_FILL_PARTICIPANTS = 'PERMISSION_FILL_PARTICIPANTS';
export const PERMISSION_EDIT_ANY_MUNICIPALITY_FUNCTIONS = 'PERMISSION_EDIT_ANY_MUNICIPALITY_FUNCTIONS';
export const PERMISSION_EDIT_ANY_FUNCTIONS = 'PERMISSION_EDIT_ANY_FUNCTIONS';
export const PERMISSION_EDIT_OWN_FUNCTIONS = 'PERMISSION_EDIT_OWN_FUNCTIONS';
export const PERMISSION_ARCHIVE_ANY_MUNICIPALITY_FUNCTION = 'PERMISSION_ARCHIVE_ANY_MUNICIPALITY_FUNCTION';
export const PERMISSION_ARCHIVE_ANY_FUNCTION = 'PERMISSION_ARCHIVE_ANY_FUNCTION';
export const PERMISSION_ARCHIVE_OWN_FUNCTION = 'PERMISSION_ARCHIVE_OWN_FUNCTION';
export const PERMISSION_RESTORE_FUNCTION = 'PERMISSION_RESTORE_FUNCTION';

export const PERMISSION_CONTENT_IDENTIFIERS = 'PERMISSION_CONTENT_IDENTIFIERS';
export const CREATE_NATIONWIDE_CONTENT_IDENTIFIER = 'CREATE_NATIONWIDE_CONTENT_IDENTIFIER';
export const CREATE_OWN_MUNICIPALITY_CONTENT_IDENTIFIER  = 'CREATE_OWN_MUNICIPALITY_CONTENT_IDENTIFIER';
export const EDIT_NATIONWIDE_CONTENT_IDENTIFIER = 'EDIT_NATIONWIDE_CONTENT_IDENTIFIER';
export const EDIT_OWN_MUNICIPALITY_CONTENT_IDENTIFIER  = 'EDIT_OWN_MUNICIPALITY_CONTENT_IDENTIFIER';
export const VIEW_NATIONWIDE_CONTENT_IDENTIFIER = 'VIEW_NATIONWIDE_CONTENT_IDENTIFIER';
export const VIEW_OWN_MUNICIPALITY_CONTENT_IDENTIFIER  = 'VIEW_OWN_MUNICIPALITY_CONTENT_IDENTIFIER';
export const ARCHIVE_NATIONWIDE_CONTENT_IDENTIFIER = 'ARCHIVE_NATIONWIDE_CONTENT_IDENTIFIER';
export const ARCHIVE_MUNICIPALITY_CONTENT_IDENTIFIER = 'ARCHIVE_MUNICIPALITY_CONTENT_IDENTIFIER';
export const RESTORE_NATIONWIDE_CONTENT_IDENTIFIER = 'RESTORE_NATIONWIDE_CONTENT_IDENTIFIER';
export const RESTORE_MUNICIPALITY_CONTENT_IDENTIFIER = 'RESTORE_MUNICIPALITY_CONTENT_IDENTIFIER';
export const PERMISSION_USERS = 'PERMISSION_USERS';
export const PERMISSION_INVITE_NEW_ADMIN = 'PERMISSION_INVITE_NEW_ADMIN';
export const PERMISSION_INVITE_NEW_USER = 'PERMISSION_INVITE_NEW_USER';
export const PERMISSION_EDIT_OTHER_USERS = 'PERMISSION_EDIT_OTHER_USERS';
export const PERMISSION_EDIT_OWN_USER_INFO = 'PERMISSION_EDIT_OWN_USER_INFO';
export const PERMISSION_REMOVE_USER = 'PERMISSION_REMOVE_USER';
export const PERMISSION_VIEW_OTHER_MUNICIPALITIES_USER_LISTS = 'PERMISSION_VIEW_OTHER_MUNICIPALITIES_USER_LISTS';
export const PERMISSION_VIEW_OWN_MUNICIPALITY_USER_LIST = 'PERMISSION_VIEW_OWN_MUNICIPALITY_USER_LIST';
export const PERMISSION_VIEW_OWN_ORGANIZATION_USER_LIST = 'PERMISSION_VIEW_OWN_ORGANIZATION_USER_LIST';
export const PERMISSION_CHANGE_OWN_PASSWORD = 'PERMISSION_CHANGE_OWN_PASSWORD';
export const PERMISSION_RESTORE_OTHER_USERS_PASSWORD = 'PERMISSION_RESTORE_OTHER_USERS_PASSWORD';
export const PERMISSION_RESTORE_OTHER_MUNICIPALITY_USERS_PASSWORD = "PERMISSION_RESTORE_OTHER_MUNICIPALITY_USERS_PASSWORD";
export const PERMISSION_SYSTEM_LOG = 'PERMISSION_SYSTEM_LOG';
export const PERMISSION_VIEW_MUNICIPALITIES_SYSTEM_LOGS = 'PERMISSION_VIEW_MUNICIPALITIES_SYSTEM_LOGS';
export const PERMISSION_VIEW_OWN_MUNICIPALITY_SYSTEM_LOG = 'PERMISSION_VIEW_OWN_MUNICIPALITY_SYSTEM_LOG';
export const PERMISSION_REPORTS = 'PERMISSION_REPORTS';
export const PERMISSION_MAKE_DATA_QUERY_OF_ALL_MUNICIPALITIES = 'PERMISSION_MAKE_DATA_QUERY_OF_ALL_MUNICIPALITIES';
export const PERMISSION_MAKE_DATA_QUERY_OF_OWN_MUNICIPALITY = 'PERMISSION_MAKE_DATA_QUERY_OF_OWN_MUNICIPALITY';
export const PERMISSION_NOTIFICATIONS = 'PERMISSION_NOTIFICATIONS';
export const PERMISSION_CREATE_SYSTEM_WIDE_NOTIFICATION = 'PERMISSION_CREATE_SYSTEM_WIDE_NOTIFICATION';
export const PERMISSION_CREATE_MUNICIPALITY_WIDE_NOTIFICATION = 'PERMISSION_CREATE_MUNICIPALITY_WIDE_NOTIFICATION';
export const PERMISSION_CREATE_ORGANIZATION_WIDE_NOTIFICATION = 'PERMISSION_CREATE_ORGANIZATION_WIDE_NOTIFICATION';
export const PERMISSION_VIEW_ANY_MUNICIPALITY = 'PERMISSION_VIEW_ANY_MUNICIPALITY';

const permissions = {
    // areas view
    [PERMISSION_AREAS]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION, ROLE_GUEST],
    [PERMISSION_CREATE_MUNICIPALITY]: [ROLE_ADMIN_SYSTEM],
    [PERMISSION_CREATE_OWN_MUNICIPALITY]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY],
    [PERMISSION_CREATE_ORGANIZATION]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION],
    [PERMISSION_EDIT_MUNICIPALITY]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY],
    [PERMISSION_EDIT_ORGANIZATION]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION],
    [PERMISSION_VIEW_MUNICIPALITIES]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION, ROLE_GUEST],
    [PERMISSION_VIEW_ORGANIZATIONS]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION, ROLE_GUEST],
    // locations view
    [PERMISSION_LOCATIONS]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION],
    [PERMISSION_CREATE_LOCATION_TO_ANY_MUNICIPALITY]: [ROLE_ADMIN_SYSTEM],
    [PERMISSION_CREATE_LOCATION_TO_OWN_MUNICIPALITY]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY],
    [PERMISSION_CREATE_LOCATION_TO_OWN_ORGANIZATION]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION],
    [PERMISSION_EDIT_LOCATION]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION],
    [PERMISSION_ARCHIVE_LOCATION]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY],
    [PERMISSION_ARCHIVE_OWN_ORGANIZATIONS_LOCATION]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION],
    [PERMISSION_RESTORE_LOCATION]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY],
    [PERMISSION_VIEW_LOCATIONS]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION],
    // functions view
    [PERMISSION_FUNCTIONS]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION],
    [PERMISSION_CREATE_FUNCTION_ANY_MUNICIPALITY]: [ROLE_ADMIN_SYSTEM], 
    [PERMISSION_CREATE_FUNCTION_BEHALF_OF_ANOTHER]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION],
    [PERMISSION_CREATE_OWN_FUNCTION]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION],
    [PERMISSION_EDIT_ANY_MUNICIPALITY_FUNCTION_TEMPLATE]: [ROLE_ADMIN_SYSTEM],
    [PERMISSION_EDIT_ANY_FUNCTION_TEMPLATE]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION],
    [PERMISSION_EDIT_OWN_FUNCTION_TEMPLATE]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION],
    [PERMISSION_CREATE_FUNCTION_TEMPLATE]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION],
    [PERMISSION_FILL_PARTICIPANTS]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION],
    [PERMISSION_EDIT_ANY_MUNICIPALITY_FUNCTIONS]: [ROLE_ADMIN_SYSTEM],
    [PERMISSION_EDIT_ANY_FUNCTIONS]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION],
    [PERMISSION_EDIT_OWN_FUNCTIONS]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION],
    [PERMISSION_ARCHIVE_ANY_MUNICIPALITY_FUNCTION]: [ROLE_ADMIN_SYSTEM],
    [PERMISSION_ARCHIVE_ANY_FUNCTION]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION],
    [PERMISSION_ARCHIVE_OWN_FUNCTION]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION],
    [PERMISSION_RESTORE_FUNCTION]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY],
    // content identifiers view
    [PERMISSION_CONTENT_IDENTIFIERS]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION, ROLE_GUEST],
    [CREATE_NATIONWIDE_CONTENT_IDENTIFIER]:[ROLE_ADMIN_SYSTEM],
    [EDIT_NATIONWIDE_CONTENT_IDENTIFIER]:[ROLE_ADMIN_SYSTEM],
    [ARCHIVE_NATIONWIDE_CONTENT_IDENTIFIER]:[ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY],
    [RESTORE_NATIONWIDE_CONTENT_IDENTIFIER]:[ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY],
    [VIEW_NATIONWIDE_CONTENT_IDENTIFIER]:[ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION, ROLE_GUEST],
    [CREATE_OWN_MUNICIPALITY_CONTENT_IDENTIFIER]:[ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY],
    [EDIT_OWN_MUNICIPALITY_CONTENT_IDENTIFIER]:[ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY],
    [ARCHIVE_MUNICIPALITY_CONTENT_IDENTIFIER]:[ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY],
    [RESTORE_MUNICIPALITY_CONTENT_IDENTIFIER]:[ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY],
    [VIEW_OWN_MUNICIPALITY_CONTENT_IDENTIFIER]:[ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION],
    // users view
    [PERMISSION_USERS]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION],
    [PERMISSION_INVITE_NEW_ADMIN]: [ROLE_ADMIN_SYSTEM],
    [PERMISSION_INVITE_NEW_USER]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION],
    [PERMISSION_EDIT_OTHER_USERS]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION],
    [PERMISSION_EDIT_OWN_USER_INFO]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION, ROLE_GUEST],
    [PERMISSION_REMOVE_USER]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION],
    [PERMISSION_VIEW_OTHER_MUNICIPALITIES_USER_LISTS]: [ROLE_ADMIN_SYSTEM],
    [PERMISSION_VIEW_OWN_MUNICIPALITY_USER_LIST]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION],
    [PERMISSION_VIEW_OWN_ORGANIZATION_USER_LIST]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION],
    [PERMISSION_CHANGE_OWN_PASSWORD]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION, ROLE_GUEST],
    [PERMISSION_RESTORE_OTHER_MUNICIPALITY_USERS_PASSWORD]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY],
    [PERMISSION_RESTORE_OTHER_USERS_PASSWORD]: [ROLE_ADMIN_SYSTEM],
    // system log view
    [PERMISSION_SYSTEM_LOG]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY],
    [PERMISSION_VIEW_MUNICIPALITIES_SYSTEM_LOGS]: [ROLE_ADMIN_SYSTEM],
    [PERMISSION_VIEW_OWN_MUNICIPALITY_SYSTEM_LOG]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY],
    // reports view
    [PERMISSION_VIEW_ANY_MUNICIPALITY]: [ROLE_ADMIN_SYSTEM],
    [PERMISSION_REPORTS]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION, ROLE_GUEST],
    [PERMISSION_MAKE_DATA_QUERY_OF_ALL_MUNICIPALITIES]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION, ROLE_GUEST],
    [PERMISSION_MAKE_DATA_QUERY_OF_OWN_MUNICIPALITY]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION, ROLE_GUEST],
    // notifications view
    [PERMISSION_NOTIFICATIONS]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION, ROLE_EMPLOYEE_MUNICIPALITY, ROLE_EMPLOYEE_ORGANIZATION, ROLE_GUEST],
    [PERMISSION_CREATE_SYSTEM_WIDE_NOTIFICATION]: [ROLE_ADMIN_SYSTEM],
    [PERMISSION_CREATE_MUNICIPALITY_WIDE_NOTIFICATION]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION],
    [PERMISSION_CREATE_ORGANIZATION_WIDE_NOTIFICATION]: [ROLE_ADMIN_SYSTEM, ROLE_ADMIN_MUNICIPALITY, ROLE_LEADER_MUNICIPALITY, ROLE_ADMIN_ORGANIZATION],
};


export const roles = [
    ROLE_ADMIN_SYSTEM,
    ROLE_ADMIN_MUNICIPALITY,
    ROLE_LEADER_MUNICIPALITY,
    ROLE_ADMIN_ORGANIZATION,
    ROLE_EMPLOYEE_MUNICIPALITY,
    ROLE_EMPLOYEE_ORGANIZATION,
    ROLE_GUEST,
]

const hierarchy = {
    ROLE_ADMIN_SYSTEM: [
        ROLE_ADMIN_SYSTEM,
        ROLE_ADMIN_MUNICIPALITY,
        ROLE_LEADER_MUNICIPALITY,
        ROLE_ADMIN_ORGANIZATION,
        ROLE_EMPLOYEE_MUNICIPALITY,
        ROLE_EMPLOYEE_ORGANIZATION,
        ROLE_GUEST
    ],
    ROLE_ADMIN_MUNICIPALITY: [
        ROLE_ADMIN_MUNICIPALITY,
        ROLE_LEADER_MUNICIPALITY,
        ROLE_ADMIN_ORGANIZATION,
        ROLE_EMPLOYEE_MUNICIPALITY,
        ROLE_EMPLOYEE_ORGANIZATION,
        ROLE_GUEST
    ],
    ROLE_LEADER_MUNICIPALITY: [
        ROLE_LEADER_MUNICIPALITY,
        ROLE_ADMIN_ORGANIZATION,
        ROLE_EMPLOYEE_MUNICIPALITY,
        ROLE_EMPLOYEE_ORGANIZATION,
        ROLE_GUEST
    ],
    ROLE_ADMIN_ORGANIZATION: [
        ROLE_ADMIN_ORGANIZATION,
        ROLE_EMPLOYEE_ORGANIZATION,
        ROLE_GUEST
    ]
}

export const organizationalRoles = [
    ROLE_ADMIN_ORGANIZATION,
    ROLE_EMPLOYEE_ORGANIZATION
];

export const hasPermission = (permissionName, user) => {
    if(!isAuthenticated(user)) {
        return false;
    }

    if(!(permissionName in permissions)) {
        return false;
    }

    const role = user.role;
    const acceptedRolesArr = permissions[permissionName];

    return valueFoundInArray(role, acceptedRolesArr);
}

export const hasRole = (roleName, user) => {
    if(!isAuthenticated(user)) {
        return false;
    }

    if(!(roles.includes(roleName))) {
        return false;
    }

    const role = user.role;
    return role === roleName;
}

export const hasPermissionToHandleMunicipality = (municipalityId, user)=> {
    if(!isAuthenticated(user)) {
        return false;
    }
    if(hasRole('ROLE_ADMIN_SYSTEM', user)) return true;

    const ownMunicipalityId = user.mainMunicipalityId;

    return ownMunicipalityId === municipalityId;
}

export const hasPermissionToHandleRole = (roleName, user)=> {
    if(!isAuthenticated(user)) {
        return false;
    }

    const role = user.role;
    if(!(role in hierarchy)) return false;
    
    return hierarchy[role].indexOf(roleName) >= 0;
}

export const isAuthenticated = (user) => {
    if(user === undefined || user === null) {
        return false;
    }

    const role = user.role;

    return valueFoundInArray(role, roles);

}