import types from "./contentIdentifier.types";
import {apiAction} from "../api/actions";
import settings from "../api/settings";

const setContentIdentifiers = (data, type) => {
    return {
        type: types.SET_CONTENT_IDENTIFIERS_LIST,
        payload: {
            data: data,
            type:type
        }
    };
}

const contentIdentifierAPICallError = error => {
    let data = "Connection error";
    if (error.response !== undefined) data = error.response.data;
    return {
        type: types.CONTENT_IDENTIFIER_API_ERROR,
        payload: data
    }
}

export const createContentIdentifier = (data, onSuccess) => {
    return apiAction({
        url: settings.baseURL + "/content_identifiers",
        method: 'POST',
        data: data,
        onSuccess: onSuccess,
        onFailure: contentIdentifierAPICallError,
        label: types.CREATE_CONTENT_IDENTIFIER
    });
};

export const listContentIdentifiers = (municipalityId) => {
    let url = `${settings.baseURL}/content_identifiers`;
    let type = 'nationwide';
    if(municipalityId !== undefined && municipalityId !== null) {
        url = `${settings.baseURL}/content_identifiers/municipality/${municipalityId}`;
        type = 'ownMunicipality';
    }
    return apiAction({
        url: url,
        onSuccess: (data) => setContentIdentifiers(data, type),
        onFailure: contentIdentifierAPICallError,
        label: types.FETCH_CONTENT_IDENTIFIERS_LIST
    });
}

export const setContentIdentifier = data => {
    return {
        type: types.SET_CONTENT_IDENTIFIER,
        payload: data
    };
}

export const unsetContentIdentifier = () => {
    return {
        type: types.UNSET_CONTENT_IDENTIFIER,
        payload: null
    };
}

export const saveContentIdentifier = (id, data, onSuccess) => {
    return apiAction({
        url: `${settings.baseURL}/content_identifiers/${id}`,
        method: 'PUT',
        data: data,
        onSuccess: onSuccess,
        onFailure: contentIdentifierAPICallError,
        label: types.UPDATE_CONTENT_IDENTIFIER
    });
}

export const archiveContentIdentifier = (id,onSuccess) => {
    return apiAction({
        url: `${settings.baseURL}/content_identifiers/${id}/archive`,
        method: 'POST',
        onSuccess: onSuccess,
        onFailure: contentIdentifierAPICallError,
        label: types.ARCHIVE_CONTENT_IDENTIFIER
    });
};


